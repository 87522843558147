export default class FilterBuilder {
  constructor(element, { id, state = {}, scenes, prefix, effect, finish }) {
    this.id = id;
    this.prefix = prefix || "FilterBuilder";
    this.effect = effect || "no-effect-start";
    this.scenes = scenes;
    this.sceneIndex = 0;
    this.state = state;
    this.element = null;
    if (typeof element === "string") {
      this.element = document.getElementById(element);
    } else {
      this.element = element;
    }

    this.modalContainer = this.createModal({ id: this.id + "-content-modal" });
    this.dialog = this.modalContainer
      .querySelector(".modal-dialog")
      .cloneNode(true);
    this.element.innerHTML = "";
    this.element.appendChild(this.modalContainer);

    this.render(
      this.scenes[this.sceneIndex],
      this.scenes[this.sceneIndex]._scene
    );

    const that = this;
    this.finish = function () {
      if (finish) finish(that);
    };
  }

  resetModal() {
    const replacingChild = this.modalContainer.querySelector(".modal-dialog");
    if (replacingChild) replacingChild.replaceWith(this.dialog.cloneNode(true));
  }

  prepareModal(aCopy) {
    this.resetModal();
    this.modal = {
      dialog: this.modalContainer.querySelector(".modal-dialog"),
      content: this.modalContainer.querySelector(".modal-content"),
      header: this.modalContainer.querySelector(".modal-header"),
      body: this.modalContainer.querySelector(".modal-body"),
      footer: this.modalContainer.querySelector(".modal-footer")
    };

    Object.keys(this.modal).forEach((section) => {
      if (section.classList) section.classList.add(`${aCopy._scene}`);
    });

    Object.keys(this.modal).forEach((section) => {
      if (section.classList)
        section.classList.add(
          `${this.prefix}__${section}--${aCopy._scene} ${aCopy._scene}`
        );
    });

    this.buttons = {
      prev: {
        label: "Back",
        container: "",
        do: function (obj) {
          obj.prev();
        }
      },
      skip: {
        label: "Skip",
        container: "",
        do: function (obj) {
          obj.skip();
        }
      },
      next: {
        label: "Next",
        container: "",
        do: function (obj) {
          obj.next();
        }
      },
      finish: {
        label: "Finish",
        container: "",
        do: function (obj) {
          obj.finish();
        }
      },
      close: {
        label: "Close",
        container: "",
        do: function (obj) {
          obj.close();
        }
      }
    };

    if (aCopy._progress)
      this.modal.footer.prepend(this.progressBar(aCopy._progress));

    const controlsContainer = this._getContainer("controls");

    Object.keys(this.buttons).forEach((key) => {
      this.buttons[key].container = this._getContainer(key, null, "button");
      this.buttons[key].container.setAttribute("data-type", key);
      this.buttons[key].container.classList.add("btn", "btn-primary");
      this.buttons[key].container.innerHTML = this.buttons[key].label;
      controlsContainer.appendChild(this.buttons[key].container);
    });

    this.modal.footer.appendChild(controlsContainer);

    const that = this;
    Object.keys(this.buttons).forEach((btn) => {
      this.buttons[btn].container.addEventListener("click", function (e) {
        that.buttons[btn].do(that);
      });
    });
  }

  render(direction) {
    const aCopy = this.scenes[this.sceneIndex];
    this.prepareModal(aCopy);
    this._isSkipable() ? this.showButton("skip") : this.hideButton("skip");
    this._isNextable() ? this.showButton("next") : this.hideButton("next");
    !this._isNextable() ? this.showButton("finish") : this.hideButton("finish");
    this._isBackable() ? this.showButton("prev") : this.hideButton("prev");
    if (aCopy._hideControls) {
      aCopy._hideControls.forEach((controlName) =>
        this.hideButton(controlName)
      );
    }
    if (this.state[aCopy._scene]) this.hideButton("skip");
    if (aCopy && aCopy._preHook) aCopy._preHook(direction, this.state, this);
    if (aCopy && aCopy._hideControl) this.hideControl();
    this._cleanContainer();
    this._renderAScene(this.scenes[this.sceneIndex], this.modal.body);
    if (aCopy && aCopy._postRender) aCopy._postRender(this.modal, this);
  }

  _renderAScene(aCopy, parentContainer) {
    // render a scene
    Object.keys(aCopy).forEach((key) => {
      let container = this._getContainer(key, aCopy._scene);
      if (!container || key.indexOf("_") === 0) return;
      if (aCopy[key]._styleClass)
        container.classList.add(...aCopy[key]._styleClass.split(" "));
      if (key === "options") {
        this.renderOptionsFromCopyKeyToContainer(aCopy, key, container);
      } else if (key.indexOf("buttons") >= 0) {
        const buttons = this._getContainer(key, aCopy._scene);
        aCopy[key].forEach((btn) => {
          buttons.appendChild(
            this._renderButton(btn, key, aCopy._scene, aCopy[key])
          );
        });
        this.modal.body.appendChild(buttons);
      } else if (key === "logo") {
        this._renderALogo(aCopy[key], container);
      } else if (key.indexOf("container") === 0) {
        this._renderAScene(aCopy[key], container);
      } else {
        if (aCopy[key]) {
          const newElement = this.createElementFromHTML(aCopy[key]);
          container.appendChild(newElement);
        }
      }
      this.resetClassesOnModal();
      if (aCopy._styleClass) {
        this.updateClassesOnModal(aCopy._styleClass);
      }
      parentContainer.appendChild(container);
    });
  }

  resetClassesOnModal() {
    // Reset styling
    Object.keys(this.modal).forEach((domElement) => {
      this.modal[domElement].className = "";
    });
    const classObject = {
      dialog: "modal-dialog modal-dialog-centered",
      content: "modal-content",
      header: "modal-header",
      body: `modal-body ${this.effect}`,
      footer: "modal-footer"
    };
    this.updateClassesOnModal(classObject);
  }
  // Reload original styling
  updateClassesOnModal(classObject) {
    Object.keys(this.modal).forEach((domElement) => {
      if (classObject[domElement]) {
        this.modal[domElement].classList.add(
          ...classObject[domElement].split(" ")
        );
      }
    });
  }
  renderOptionsFromCopyKeyToContainer(copy, keyCopy, optionContainer) {
    let scene = copy._scene;
    let optionForCopy = copy.options;
    let optionsArray = copy[keyCopy];
    let optionType = optionForCopy ? optionForCopy._type : undefined;
    let _listOfOptionsUsingTheSameRenderCode = [
      "select_button",
      "radio_button",
      "button",
      "checkbox",
      "infobox"
    ];
    if (_listOfOptionsUsingTheSameRenderCode.includes(optionType)) {
      this.createElementsFromOptionType(
        scene,
        optionType,
        optionsArray,
        optionContainer
      );
    } else if (optionType === "select_size") {
      this.createElementsFromOptionType(
        scene,
        optionType,
        optionsArray.available_size,
        optionContainer
      );
    } else {
      console.error("Option Type is not supported");
    }
  }

  createElementsFromOptionType(
    scene,
    optionType,
    optionsArray,
    optionContainer
  ) {
    Object.keys(optionsArray).forEach((key) => {
      let optionElement = this.createElementFromTemplate(
        scene,
        optionType,
        optionsArray,
        key
      );
      if (optionElement) optionContainer.appendChild(optionElement);
    });
  }

  createElementFromTemplate(scene, type, optionsArray, key) {
    const _typeToFunc = {
      radio_button: (payload, key, scene, optionsArray) =>
        this._renderOption(payload, key, scene, optionsArray),
      select_button: (payload, key, scene, optionsArray) =>
        this._renderButton(payload, key, scene, optionsArray),
      button: (payload, key, scene) =>
        this._renderButton(payload, key, scene, optionsArray),
      infobox: (payload, key, scene) =>
        this._renderInfo(payload, key, scene, optionsArray),
      select_size: (payload, key, scene, optionsArray) =>
        this._renderSizeBox(payload, key, scene, optionsArray),
      checkbox: (payload, key, scene, optionsArray) =>
        this._renderCheckBox(payload, key, scene, optionsArray)
    };
    let payload = optionsArray[key];
    if (typeof key === "string" && key.indexOf("_") === 0) return;
    let renderResult = _typeToFunc[type](payload, key, scene, optionsArray);
    return renderResult;
  }

  createElementFromHTML(htmlString) {
    var div = document.createElement("div");
    div.insertAdjacentHTML("beforeend", htmlString.trim());
    return div.childNodes[0];
  }

  _getElement(type, data) {
    const elements = {
      button: (obj) => this.button(obj),
      radio: (obj) => this.radio(obj),
      checkbox: (obj) => this.checkbox(obj),
      size: (obj) => this.size(obj),
      infobox: (obj) => this.infobox(obj)
    };
    return elements[type](data);
  }

  /**
   * A "logo" image and its attributes for including in a Scene
   *
   * @typedef   Logo
   * @property  {string}  src - uri of image
   * @property  {string}  [alt] - strongly recommended but optional; defaults to empty string if not provided
   * @property  {number}  [width] - recommended but optional;
   * @property  {number}  [height] - recommended but optional;
   * @property  {string}  [loading] - optional; defaults to loading lazy, only when width and height are also defined
   * @property  {string[]}  [classes] - optional; array of classnames to apply to logo
   */

  /**
   * Add "logo" image and attributes to scene
   *
   * @NOTE passing a string is deprecated as it conflicts with best practise for defining images, e.g., in accessible, CLS issues, blocking render
   *
   * @TODO find all uses of string logo and convert to object definitions
   * @TODO remove this logic and the type check above when all logos are converted to object definitions
   *
   * @param   {(Logo|string)}   logo
   * @param   {HTMLElement}     container
   * @return  {void}
   */
  _renderALogo(logo, parentContainer) {
    if (!typeof logo === "object" || !typeof logo === "string") return;

    const div = document.createElement("div");
    const image = document.createElement("img");

    // Type check for object and NOT array because array also reports
    // as "object" in typeof check
    if (typeof logo === "object" && !Array.isArray(logo)) {
      if (!logo?.src) return;

      image.src = logo.src;
      image.alt = logo?.alt ? logo.alt : "";

      if (logo?.classes && logo.classes.length) {
        image.classList.add(...logo.classes);
      }

      // Only set the following properties if both width and height are
      // specified to stop strange sizing and CLS issues
      if (logo?.width && logo?.height) {
        image.width = logo.width;
        image.height = logo.height;
        image.loading = logo?.loading ? logo.loading : "lazy";
      }
    }

    // @NOTE deprecated; left for compatibility
    if (typeof logo === "string") {
      image.src = logo;
      image.alt = "";
    }

    image.decoding = "async";
    parentContainer.appendChild(div.appendChild(image));
  }

  _renderButton(btnObj, key, scene, buttons) {
    const newButton = this._getElement("button", btnObj);
    newButton.classList.add(`${this.prefix}__button--${scene}`);
    if (btnObj._styleClass)
      newButton.classList.add(...btnObj._styleClass.split(" "));
    newButton.addEventListener("click", (e) => {
      if (buttons)
        Object.keys(buttons).forEach(
          (btnKey) => delete buttons[btnKey].checked
        );
      newButton.parentNode.querySelectorAll("button").forEach((anchor) => {
        anchor.classList.remove("active");
      });
      newButton.querySelector("button").classList.add("active");
      btnObj.checked = true;
      if (typeof scene !== "undefined" && btnObj[key]) {
        if (btnObj[key].key) {
          this.updateState(scene, btnObj[key].key);
        } else {
          this.updateState(scene, btnObj[key]);
        }
      }
      if (this.state[scene]) this.hideButton("skip");
      this.next();
    });
    return newButton;
  }

  _renderOption(optionObj, key, scene, allOptions) {
    optionObj.key = scene;
    optionObj.checked = this.state[scene] === key;

    const newRadio = this._getElement("radio", optionObj);
    const that = this;
    function radioClick() {
      that.updateState(scene, key);
      if (that.state[scene]) that.hideButton("skip");
      that.next();
    }
    newRadio.addEventListener("click", () => radioClick(), { once: true });
    return newRadio;
  }

  _renderSizeBox(availability, size, scene, allSizes) {
    const prefix = this.prefix;
    let genderLabel;
    genderLabel = "M" + size + "/" + "W" + this._getWomenSize(size);
    if (this.state.gender === "mens") genderLabel = "M" + size;
    if (this.state.gender === "womens") genderLabel = "W" + size;
    let available = availability * 1;
    let sizeObj = {
      size: size,
      label: genderLabel,
      availability: available,
      checked: size == this.state[scene]
    };
    let newSizeBox = this._getElement("size", sizeObj);
    if (available) {
      newSizeBox.addEventListener("click", (e) => {
        const button = e.target;
        const allSizeButtons = button.parentNode.getElementsByClassName(
          `${prefix}__size`
        );
        Array.from(allSizeButtons).forEach((el) =>
          el.classList.remove("active")
        );
        button.classList.add("active");
        this.updateState(scene, size);
        this.next();
      });
    }
    return newSizeBox;
  }

  _renderInfo(infoObj, key, scene) {
    infoObj.key = key;
    return this._getElement("infobox", infoObj);
  }
  _renderCheckBox(optionObj, key, scene) {
    const prefix = this.prefix;
    optionObj.key = key;
    const newCheckBox = this._getElement("checkbox", optionObj);
    newCheckBox.addEventListener("click", (e) => {
      optionObj.checked = newCheckBox.querySelector("input").checked || false;
      this.updateState(
        scene,
        this._getSelectedChbox(
          this.modal.body.querySelector(`.${prefix}__options--js`)
        )
      );
      if (this.state[scene]) this.hideButton("skip");
    });
    return newCheckBox;
  }

  _getSelectedChbox(frm) {
    var selchbox = []; // array that will store the value of selected checkboxes
    // gets all the input tags in frm, and their number
    var inpfields = frm.getElementsByTagName("input");
    var nr_inpfields = inpfields.length;
    // traverse the inpfields elements, and adds the value of selected (checked) checkbox in selchbox
    for (var i = 0; i < nr_inpfields; i++) {
      if (inpfields[i].type == "checkbox" && inpfields[i].checked == true)
        selchbox.push(inpfields[i].value);
    }
    return selchbox;
  }

  _getContainer(name, scene, overrideContainerElement) {
    const prefix = this.prefix;
    let element = document.createElement(overrideContainerElement || "div");
    element.classList.add(
      `${prefix}__${name}${scene ? "--" + scene : "--extra"}`,
      `${prefix}__${name}`,
      `${prefix}__${name}--js`
    );
    return element;
  }

  _cleanContainer() {
    this.modal.body.innerHTML = "";
  }

  _getNotAvailable() {
    return options.shoe_size.allsize.filter(options.shoe_size.available);
  }

  next() {
    if (
      (this.isStateAvailable() || this._isSkipable()) &&
      this.scenes[this.sceneIndex + 1]
    ) {
      // loading next scene
      if (this.scenes[this.sceneIndex + 1]) this.sceneIndex++;
      this.render("next");
    } else {
      // Show error
    }
  }

  prev() {
    if (this.isStateAvailable() || this._isBackable()) {
      // loading previous scene
      this.sceneIndex--;
      this.render("prev");
    } else {
      // Show error
    }
  }

  skip(direction) {
    // skip this check
    direction === "prev" ? this.prev() : this.next();
  }

  close() {
    $(this.modalContainer).modal("hide");
  }

  jump(step) {
    if (Number.isInteger(step)) {
      Array(step)
        .fill(0)
        .forEach((el) => this.skip("next"));
    }
  }

  hideControl() {
    Object.keys(this.buttons).forEach((btn) => {
      this.hideButton(btn);
    });
  }

  hideButton(buttonName) {
    this.buttons[buttonName].container.classList.add("d-none");
  }

  showButton(buttonName) {
    this.buttons[buttonName].container.classList.remove("d-none");
  }

  closeFinder() {
    // Close the finder window
  }

  updateState(name, data) {
    // Update state of filter
    this.state[name] = data;
  }

  isStateAvailable() {
    return this.state[this.scenes[this.sceneIndex]._scene];
  }

  _isSkipable() {
    // this is skipable when it is not required
    // or if required then check the state has been filled
    if (!this._isNextable()) return false;
    if (this.scenes[this.sceneIndex]._required) {
      return (
        typeof this.state[this.scenes[this.sceneIndex]._name] !== "undefined"
      );
    } else {
      return !this.scenes[this.sceneIndex]._required;
    }
  }

  _isNextable() {
    return this.sceneIndex + 1 < this.scenes.length;
  }

  _isBackable() {
    return this.sceneIndex - 1 >= 0;
  }

  updateProgress() {
    // update progress
  }

  showModal() {
    $(this.modalContainer).modal("show");
    this.modalContainer.style.paddingRight = "0px";
    document.querySelector(".modal-backdrop").style.zIndex = "100"; // backdrop to be over header top section
  }

  hideModal() {
    $(this.modalContainer).modal("hide");
  }

  getURLParams() {
    const queryString = Object.keys(this.state)
      .map((key) => {
        if (
          typeof key === "undefined" ||
          typeof this.state[key] === "undefined"
        )
          return;
        return key + "=" + this.state[key];
      })
      .filter((el) => {
        return el !== undefined;
      })
      .join("&");
    return queryString;
  }

  _getWomenSize(size) {
    return size * 1 + 2;
  }

  // Template for HTML
  button(btnObj) {
    const htmlString = `
      <div class="${this.prefix}__button">
        <button class="btn ${this.prefix}__button--link ${
      btnObj.class ? btnObj.class.join(" ") : ""
    } ${btnObj.checked ? "active" : ""}">${
      btnObj.label || "Need label!"
    }</button>
      </div>`;
    let newBtn = this.createElementFromHTML(htmlString);
    if (btnObj.do)
      newBtn.addEventListener("click", () => {
        btnObj.do(this, this.state);
      });
    return newBtn;
  }

  size(sizeObj) {
    let newSizeBox = `<div class="${this.prefix}__size ${this.state.gender} ${
      !sizeObj.availability
        ? `${this.prefix}__size--not-available`
        : `${sizeObj.checked ? "active" : ""}`
    }">${sizeObj.label}</div>`;
    return this.createElementFromHTML(newSizeBox);
  }

  radio(optionObj) {
    let newRadio = `<div class="custom-control custom-radio ${
      this.prefix
    }__radio">
      <input id="custom-${optionObj.label
        .toLowerCase()
        .replace(/[^\w]/gi, "-")}" name="${
      optionObj.key
    }" type="radio" class="custom-control-input" ${
      optionObj.checked ? "checked" : ""
    }>
      <label class="custom-control-label" for="custom-${optionObj.label
        .toLowerCase()
        .replace(/[^\w]/gi, "-")}" value="${optionObj.key}">${
      optionObj.label
    }</label>
      <div class="custom-control-description ${
        !optionObj.description ? "hidden" : ""
      }">${optionObj.description}</div>
    </div>`;
    return this.createElementFromHTML(newRadio);
  }

  checkbox(checkboxObj) {
    let newCheckBox = `<div class="custom-control custom-radio ${
      this.prefix
    }__radio">
      <input id="custom-${
        checkboxObj.key
      }" name="custom-checkboxes" type="checkbox" class="custom-control-input" value="${
      checkboxObj.key
    }" ${checkboxObj.checked ? "checked" : ""}>
       <label class="custom-control-label" for="custom-${checkboxObj.key}">${
      checkboxObj.label
    }</label>
      <div class="custom-control-description ${
        !checkboxObj.description ? "hidden" : ""
      }">${checkboxObj.description}</div>
    </div>`;
    return this.createElementFromHTML(newCheckBox);
  }

  infobox(infoObj) {
    const logos = infoObj.logos
      ? infoObj.logos
          .map((logo) => {
            return `<img src="${logo}" loading="lazy" decoding="async" />`;
          })
          .join("")
      : "";

    let newInfoObj = `<div class="${this.prefix}__info-box ${
      this.prefix
    }__button--js">
      <div class="${this.prefix}__info-box--logo">
        ${logos ? logos : `<img src="${infoObj.logo}" />`}
      </div>
      <div class="${this.prefix}__info-box--description">${
      infoObj.description
    }</div>
    </div>`;
    return this.createElementFromHTML(newInfoObj);
  }

  createModal(modalObj) {
    let newModal = `<div
      id="${modalObj.id}"
      class="${modalObj.name || this.prefix} modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">${this.closeSvg()}</span>
            </button>
          </div>
          <div class="modal-body">
          </div>
          <div class="modal-footer">
          </div>
        </div>
      </div>
    </div>`;
    return this.createElementFromHTML(newModal);
  }

  closeSvg() {
    return `<svg class="close-x" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 75 75" enable-background="new 0 0 75 75" xml:space="preserve">
    <path d="M43.2,37.5l18.6-18.6c1.6-1.6,1.6-4.1,0-5.7c-1.6-1.6-4.1-1.6-5.7,0L37.5,31.8L18.9,13.2  c-1.6-1.6-4.1-1.6-5.7,0c-1.6,1.6-1.6,4.1,0,5.7l18.6,18.6L13.2,56.1c-1.6,1.6-1.6,4.1,0,5.7c1.6,1.6,4.1,1.6,5.7,0l18.6-18.6  l18.6,18.6C56.9,62.6,58,63,59,63s2.1-0.4,2.9-1.2c1.6-1.6,1.6-4.1,0-5.7L43.2,37.5z"/>
    </svg>`;
  }

  progressBar(percentage) {
    const percentageBar = `<div class="${this.prefix}__progress--bar">
      <label>Your progress</label>
      <div class="progress ${this.prefix}__progress">
        <div class="progress-bar" role="progressbar" aria-valuenow="${percentage}"
        aria-valuemin="0" aria-valuemax="100" style="width: ${percentage}%">
        </div>
      </div>
    </div>`;
    return this.createElementFromHTML(percentageBar);
  }
}
