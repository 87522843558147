import $ from "expose-loader?exposes=$,jQuery!jquery";
import "bootstrap/js/dist/tab";
import "bootstrap/js/dist/modal";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/collapse";

// core version + navigation, pagination modules:
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay]);

import "./addons/_polyfill.ie11";
import "./addons/searchBar";
import "./addons/myAccount";
import Cookies from "js-cookie";
import "./addons/perfect-footbed";
import "./addons/side-widget";
import "./addons/mail-popup";
import CartOverlay from "./cart/cart-overlay";
import regionDetection from "./popups/region-detection";
import "./addons/footbed-finder";
import "./addons/footbed-tutorial";
import { sendDesktopNavigationEvents, sendMobileNavigationEvents } from "../shared/_mainNavEvents";

// Lazy load images
import LazyLoad from 'vanilla-lazyload';
const LazyLoadInstance = new LazyLoad({threshold: 0.3});
window.LazyLoadInstance = LazyLoadInstance;

if (window.location.href.match("/account/")) {
  window.hide_mailing_list_popup = 1;
}

// Variables for mailing-list
window.mailing_list = {
  disable_signup: window.hide_mailing_list_popup || 0,
  subscribe_url: window.settings.yoursole_url + "/" + window.settings.region + "/mailing-list/subscribe"
};
import "./popups/mailing-list";

document.addEventListener("DOMContentLoaded", function () {
  // Get the current year an place on footer copy
  var currentYear = new Date().getFullYear();
  $(".current-year").html(currentYear);

  var $closeBtn = $(".closeBtn");
  $closeBtn.onclick = function () {
    $(this)
      .closest(".message")
      .slideUp();
  };

  var $shopbymenu = $(".shopbymenu .mainmenu a");
  $($shopbymenu).on("click", function (evt) {
    $(".mainmenu").addClass("active");
    $($shopbymenu).removeClass("active");
    $(this).toggleClass("active");
    $(".shopbypanel").slideDown("fast");
  });


  // Accordion
  var $accordions = $(".accordion-header");
  var toggleButtonContentAccordion = function (element) {
    element.html() === "-" ? element.html("+") : element.html("-");
  };

  // check if accordion state is open and change the button toggle
  $accordions.each(function () {
    var status = $(this).data("state"),
      $thisButtonContent = $(this).find(".close"),
      $accordion = $(this).closest(".accordion");

    if (status === "active") {
      toggleButtonContentAccordion($(this).find(".close"));
      $($accordion).addClass("active");
    }

    $(this).on("click", function () {
      if (status === "active") {
        $($accordion).toggleClass("active");
        toggleButtonContentAccordion($thisButtonContent);
        $(this).data("state", "no-active");
      } else {
        $($accordion).toggleClass("active");
        toggleButtonContentAccordion($thisButtonContent);
        $(this).data("state", "active");
      }
    });
  });

  // Dropdown Sort By '
  var $dropdownSortBy = $(".dropdown");
  $($dropdownSortBy)
    .find("header")
    .on("click", function () {
      $(this)
        .siblings()
        .closest("ul")
        .toggleClass("active");
    });

  // Form
  var required = $("label.required");
  $(required).each(function () {
    $(this).append("<span class='required'>*</span>:");
  });

  // Main nav toggle YOURSOLE 2.0
  function hideMobileNavIconBars() {
    $('.navbar-toggle .icon-bar').hide();
    $('.mobile-nav-close').show();
    $("#navbar-panel-Mobile").css('display', 'block')
    // $(".navbar-toggle").css('margin-top', '0px');
    $(".mobile-nav-close").css('display', 'block');
    document.body.style.overflow = "hidden";
  };

  function showMobileNavIconBars() {
    $('.navbar-toggle .icon-bar').show();
    $('.navbar-toggle').css('margin-top', '4px');
    $('.mobile-nav-close').hide();
    document.body.style.overflow = "auto";
    window.setInterval(function () { if (!$('#mypanel').hasClass('active')) { $("#navbar-panel-Mobile").css('z-index', 'unset').css('display', 'none') } }, 500);
  };

  $('#mp-menu').on('click', function () {
    if (($('.mp-level.active'))) {
      var height = window.innerHeight - 62;
      $('#mypanel').height(height);
    };
  });

  $(".navbar-toggle").on("click", function (evt) {
    evt.preventDefault();
    if (!window.preventNavbarFromOpen) {
      $("#mypanel").toggleClass("active");
      $("body")
        .find(".mp-level")
        .removeClass("active");
      $("#navbar-panel-Mobile").css('z-index', '13');
    }
    if (!$('#mypanel').hasClass('active')) {
      showMobileNavIconBars();
    } else {
      hideMobileNavIconBars();
    }
  });

  $('#mp-menu ul li a').on('click', function () {
    $('#mypanel').animate({ scrollTop: 0 }, "fast");
  });

  // Button btn-active for menu - find the .mp-level
  $(".btn-active").on("click", function (evt) {
    var element = $(this)
      .parent()
      .children(".mp-level:first")
      .addClass("active");
  });

  // Back button hide t
  $(".mp-back").on("click", function (evt) {
    $(this)
      .parent()
      .toggleClass("active");
  });

  // Button btn-simple for menu - find the .mp-level
  $(".btn-simple").on("click", function (evt) {
    evt.preventDefault();
    if ($(this).hasClass("active")) {
      $(this)
        .parent()
        .children(".mp-simple:first")
        .hide("slow");
      $(this).removeClass("active");
    } else {
      $(this)
        .parent()
        .children(".mp-simple:first")
        .show("slow");
      $(this).addClass("active");
    }
  });

  // Main Nav and sub Navigation highlight Desktop

  var highlight = document.createElement("span");

  var selectors = {
    mainli: document.querySelectorAll(".mainli"),
    mainNav: document.querySelector("#mainNav"),
    mainNavUl: document.querySelector("#mainNavUl"),
    mainNavli: document.querySelector("#mainNav > ul > li"),
    backgroundPanel: document.querySelector("#desktop-nav .dropdownMenu"),
    mainlink: document.querySelectorAll(".mainlink"),
    $subnav: $("#subnav"),
    $menu: $(".menu"),
    $navWrapper: $(".nav-wrapper"),
    $mainnavBtn: $(
      "#mens, #womens, #health, #aboutus, #cart, #subnav.active a, .cart-menu"
    ),
    $navbarToggle: $(".navbar-toggle"),
    $mpBack: $(".mp-back"),
    $btnActive: $(".btn-active"),
    $btnSimple: $(".btn-simple"),
    $desktopNav: $("#desktop-nav"),
    $mobileNav: $("#navbar-top-Mobile"),
    $wrapper: $("#wrapper")
  };

  // var adjustDesktopNav = function() {
  //   var desktopNavHeight = $(selectors.$desktopNav).outerHeight();
  //   var navbarTopMobileHeight = $("#navbar-top-Mobile").outerHeight();
  //   if (desktopNavHeight != 0) {
  //     $(selectors.$wrapper).css("padding-top", desktopNavHeight + "px");
  //   } else {
  //     $(selectors.$wrapper).css("padding-top", navbarTopMobileHeight + "px");
  //   }
  // };

  function adjustMyPanel() {
    var mobileNavHeight = document.getElementById("navbar-top-Mobile")
      .offsetHeight;
    var myPanel = document.getElementById("mypanel");
    if (myPanel && myPanel.style) myPanel.style.top = mobileNavHeight + "px";
  }

  // adjustDesktopNav();
  adjustMyPanel();

  $(window).resize(function () {
    setTimeout(function () {
      // adjustDesktopNav();
      adjustMyPanel();
    }, 300);
  });

  $(highlight).addClass("highlight");

  if (
    selectors.mainNav &&
    selectors.mainNav != null &&
    selectors.mainNav != undefined
  ) {
    $(".hightlightHolder").append(highlight);
  }

  var menuIsOpen = false;

  var mainMenu = {
    linksEnter: function () {
      var $this = $(this);
      $(".mainli").removeClass("trigger-enter").removeClass("trigger-enter-active");
      $this.children(".dropdown-links-desktop").addClass("active");
      $this.addClass("trigger-enter");
      $this.addClass("trigger-enter-active");
      menuIsOpen = true;
    },
    linksLeave: function () {
      $(".mainli").removeClass("trigger-enter").removeClass("trigger-enter-active");
    }
  };

  selectors.mainli.forEach((a) =>
    a.addEventListener("mouseenter", mainMenu.linksEnter)
  );

  $('#mainNav').on('mouseleave', function () {
    $('.mainli').removeClass('trigger-enter-active').removeClass('trigger-enter');;
  });

  $(".dropDown").on("mouseenter", function () {
    $(this).addClass("is-active");
  });
  $(".dropDown-link").on("click", function (e) {
    $(this).toggleClass("is-active");
  });
  $(".dropDown").on("mouseleave", function () {
    $(this).removeClass("is-active");
  });

  $.urlParam = function (name) {
    var results = new RegExp("[?&]" + name + "=([^&#]*)").exec(
      window.location.href
    );
    if (results == null) {
      return null;
    } else {
      return results[1] || 0;
    }
  };

  $("#myModal").on("shown.bs.modal", function () {
    $("#myInput").focus();
  });

  var level = $.urlParam("level");
  var link = $.urlParam("link");

  if (level != "null") {
    $(".template-level").html(level);
  }

  if (link != "null") {
    $(".template-link").html(link);
    $(".product-list a").attr("href", function (i, href) {
      return href + "?link=" + link + "&level=" + level;
    });
    $(".product-list-view a").attr("href", function (i, href) {
      return href + "?link=" + link + "&level=" + level;
    });
  }
});

$(document).ready(function () {
  window.addEventListener('scroll', function () {
    let alertSwipe = document.querySelector(".alert-swiper.message");
    let wrapper = document.getElementById("wrapper");
    let desktopNav = document.getElementById("desktop-nav");
    let mobileNav = document.getElementById("navbar-top-Mobile");
    function isHidden(el) {
      var style = window.getComputedStyle(el);
      return (style.display === 'none')
    }
    if (window.scrollY > 0) {
      if (desktopNav && desktopNav.style) desktopNav.style.position = 'fixed';
      if (mobileNav && mobileNav.style) mobileNav.style.position = 'fixed';
      if (wrapper) wrapper.style.paddingTop = !isHidden(desktopNav) ? desktopNav.offsetHeight + 'px' : mobileNav.offsetHeight + 'px';
    } else {
      if (desktopNav && desktopNav.style) desktopNav.style.position = 'relative';
      if (mobileNav && mobileNav.style) mobileNav.style.position = 'relative';
      if (wrapper) wrapper.style.paddingTop = 'unset';
    }
  })

  const cartOverlay = new CartOverlay(document.getElementById('cart-overlay'), {
    config: {
      currency: window.catalog_cfg.currency,
      currency_symbol: window.catalog_cfg.currency_symbol,
      media_root: window.settings.media_root,
      yoursole_url: window.settings.yoursole_url,
      region: window.settings.region,
      free_shipping_amount: catalog_cfg.FREE_SHIPPING_AMOUNT,
      free_shipping_login : catalog_cfg.FREE_SHIPPING_LOGIN,
      shipping_flat_rate_limit: catalog_cfg.SHIPPING_FLAT_RATE_LIMIT
    }
  });

  cartOverlay.getCart();

  window.cart_overlay = {
    renderCart: cartOverlay.renderCart.bind(cartOverlay),
    showCart: cartOverlay.showCart.bind(cartOverlay),
    hideCart: cartOverlay.hideCart.bind(cartOverlay),
    getCart: cartOverlay.getCart.bind(cartOverlay),
    updateCartBubble: cartOverlay.updateCartBubble.bind(cartOverlay),
    showCartforSecond: cartOverlay.showCartforSecond.bind(cartOverlay),
    applyDiscountCode: cartOverlay.applyDiscountCode.bind(cartOverlay),
    cartOverlayInstance: cartOverlay
  };

  $("#mp-menu ul li").on('click', function (e) {
    $("#mp-menu button").each(function (index) {
      var classList = $(this).attr('class').split(/\s+/);
      if ($(this) !== e.target && classList.includes('active')) {
        $(this).removeClass('active');
        $(e.target).addClass('active');
      };
    });
  });

  /**
    * Add event listener to Giving Back button
    *
    * TODO remove when giving back promotion is over
    */
  if (document.querySelectorAll("[href$='#giving-back']").length) {
    document.querySelectorAll("[href$='#giving-back']").forEach((link) => link.addEventListener("click", (event) => {
      // Prevent reload, but update window history in case user wishes to reload
      event.preventDefault();
      if (!window.location.href.includes("#giving-back")) window.history.pushState({}, '', window.location + "#giving-back");

      $("#health_care_heroes").modal("show");
    }));
  }

  if (window.location.hash && window.location.hash == '#giving-back') {
    $("#health_care_heroes").modal("show");
  }

  if (window.location.hash) {
    if (document.getElementById(`${window.location.hash}-modal`.replace('#', ''))) {
      $(`${window.location.hash}-modal`).modal("show");
    }
  }

  // Ajax region detect
  $.ajax({
    url: window.settings.yoursole_url + "/" + window.settings.region + "/region-detect",
    type: "GET",
    data: { 'page': window.location.pathname },
    success: function (data) {
    }
  });

  new Swiper(".swiper-alert", {
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      disabledClass: "d-none"
    },
    autoplay: {
      delay: 7000,
      disableOnInteraction: true
    },
    on: {
      update: function (swiper) {
        const slideEnabled = document.querySelectorAll(".alert-swiper .swiper-slide").length > 1;
        swiper.enabled = slideEnabled;
      }
    }
  })

  // Region Detection
  const queryString = window.location.search;
  const urlParam = new URLSearchParams(queryString);
  if (!Cookies.get('ip') || urlParam.get('force-ip')) {
    if (localStorage && localStorage.getItem('location')) localStorage.removeItem('location');
    regionDetection.detect();
  }
  $('#nav-region').on('click', function () {
    regionDetection.getPopupDisplay();
  });
  $('#mobile-nav-region').on('click', function () {
    regionDetection.getPopupDisplay();
  })
  if (Cookies.get('intl_shipping') === 'on') {
    // Toggle CA and Intl flag visibility
    $('#nav-region .region-flag__icon').attr('hidden', '');
    $('#nav-region .region-flag__icon--intl').removeAttr('hidden');

    // Toggle mobile CA and Intl flag visibility
    $('#mobile-nav-region .region-flag__icon').attr('hidden', '');
    $('#mobile-nav-region .region-flag__icon--intl').removeAttr('hidden');
  }

  // Main Navigation Events Tracking
  if (document.querySelectorAll("#mainNav, #mp-menu").length) {
    document.querySelector("#mainNav").addEventListener("click", sendDesktopNavigationEvents);
    document.querySelector("#mp-menu").addEventListener("click", sendMobileNavigationEvents);
  }
});
