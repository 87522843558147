import analytics from "~/shared/_analytics";

/**
 * Recursive check of the previous sibling (i.e., `<li>`) for the presence
 * a "heading link" and return that element's innerText; Recursive function to
 * only traverse as much of the DOM as necessary
 *
 * @params    {HTMLElement}     currentNode - expects a list item in the `#mainNav`
 * @returns   {string|getNearestParent}
 */
function getNearestParent(currentNode) {
  const previous = currentNode.previousElementSibling;

  // Weird exception: some of our navigation columns are nested inside a `ul`
  // so if this is one of those columns, get the "heading link" from the first
  // list item of the parent `ul`
  if (currentNode.closest("ul").querySelectorAll(":scope > .row").length > 0) {
    return currentNode.closest("ul").querySelector("li:first-child a h3").innerText;
  }

  // No previous sibling, i.e., no siblings left to traverse
  if (!previous) return "";

  if (previous.querySelectorAll("a h3").length > 0) {
    return previous.querySelector("a h3").innerText;
  }

  // Continue recursively checking
  return getNearestParent(previous);
};

/**
 * Initialize tracking event on the main navigation; used to determine which links
 * are the most useful for our users.
 *
 * NOTE: this whole callback is super brittle. It assumes a lot about the
 * current navigation structure, so if it changes, these events will need to
 * be modified as well.
 *
 * @params {MouseEvent} event
 */
export function sendDesktopNavigationEvents(event) {
  if (event.target.closest("a") == null) return;

  const target = event.target;
  const isMainLink = target.classList.contains("mainlink");
  const isParentLink = target.closest("a").querySelectorAll("h3").length > 0;

  const targetText = target.closest("a").innerText;

  const labelText = target.closest(".mainli").querySelector(".mainlink").innerText || "Top-Level";

  // Default parentText is empty;
  // If not mainlink AND not a `a > h3`, then begin recursive check;
  // If contains `a > h3` return heading string
  let parentText = "";
  if (!isMainLink && !isParentLink) parentText = getNearestParent(target.closest("li"));
  if (!isMainLink && isParentLink) parentText = targetText;

  let valueText = (!isMainLink && !isParentLink)
    ? `${labelText} > ${parentText} > ${targetText}`
    : `${labelText} > ${parentText}`;

  // console.log("Nav:Desktop", labelText, valueText);

  analytics.sendCustomEvent("Nav:Desktop", "click", valueText);
};

/**
 * Initialize tracking event on the main mobile navigation; used to determine
 * which links are the most useful for our users.
 *
 * NOTE: ideally this would be a single function for both desktop and mobile
 * navigation, however, the DOM structures are different enough to warrant
 * making a second callback
 *
 * NOTE: this whole callback is super brittle. It assumes a lot about the
 * current navigation structure, so if it changes, these events will need to
 * be modified as well.
 *
 * @params {MouseEvent} event
 */
export function sendMobileNavigationEvents(event) {
  // Return early if clicked target was not a link or was a "back" link
  if (event.target.closest("a") == null) return;
  if (event.target.closest("a").classList.contains("mp-back")) return;

  const target = event.target;
  const labels = document.querySelectorAll(".mp-level.active");

  // Default labelText is for "Top-Level" navigation items;
  // If the user has gone down only one level, labelText should be the title for that level
  // If the user has gone down multiple levels, labelText should be the title for that level
  let labelText = "Top-Level";
  if (labels.length === 1) labelText = labels[0].querySelector("h4").innerText;
  if (labels.length > 1) labelText = [...labels].map(label => label.querySelector("h4").innerText).join(" > ");

  const parentText = (!!target.closest(".collapse"))
    ? target.closest(".collapse").previousElementSibling.querySelector("button").innerText
    : "";

  const valueText = (parentText)
    ? `${labelText} > ${parentText} > ${target.closest("a").innerText}`
    : `${labelText} > ${target.closest("a").innerText}`;

  // console.log("Nav:Mobile", labelText, valueText);

  analytics.sendCustomEvent("Nav:Mobile", "click", valueText);
};
